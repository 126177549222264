:root {
    --blue: #1e90ff;
    --white: #ffffff;
    --background: #000;
    --innerbackground:rgba(255,255,255,0.05);
    --shadow:rgba(255, 255, 255, 0.2) 0px 7px 15px 0px;
    --shadowlight: rgba(211, 211, 211, 0.2) 0px 2px 8px 0px;
    --color1:#F37620;
    
  }
  body{font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;color: white;
    background-color: #000;
    
}
hr{margin: 5px;}
 /* .btn{padding: px 20px;} */
  .row{padding: 0;margin: 0;}
  .col-md-*,.col-sm-*,.col-lg-*{padding: 0;margin: 0;}











  .textlogoimg {
    width: 160px;
}

.navbar-ministudy {
    padding: 12px;
    margin: 0;
     /* Ensure background color matches your theme */
}

.navlogo {
    width: 200px;
}

.navbar-ministudy ul {
    text-decoration: none;
    display: flex;
    justify-content: space-evenly;
    margin: 0;
}

.navbar-ministudy li {
    list-style-type: none;
    float: left;
    border-radius: 15px;
    position: relative;
    transition: 0.6s linear;
    cursor: pointer;
    padding: 6px 12px;
    overflow: hidden;
    margin-right: 10px;
}

.navbar-ministudy li:before {
    width: 0;
    content: '';
    background-color: white;
    position: absolute;
    height: 100%;
    transition: width 0.6s ease;
    left: 0;
    top: 0;
    z-index: -1;
}

.navbar-ministudy li:hover:before {
    width: 100%;
    height: 100%;
}

.nav-links a{color: white;}

.navbar-ministudy li:hover {
    color: black;
}


.nav-links li.activenav,
.side-nav-links li.activenav {
    background-color: white;
    color: black;
}

/* Hamburger menu icon */
.menu-toggle {
    display: none;
    font-size: 30px;
    cursor: pointer;
}

/* Side menu */
.side-menu {
    position: fixed;
    top: 0;
    right: -250px;
    width: 200px;
    height: auto;
    transition: 0.3s;
    z-index: 2000;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0,0,0,0.5);
    background: rgba(255, 255, 255, 0.05); /* Light frosted effect */
    backdrop-filter: blur(20px); /* Blur effect */
}

.side-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.side-menu a{text-decoration: none;color: white;}
.side-menu li {
    padding: 10px 0;
    color: white;
    cursor: pointer;
    text-align: center;     
    border-radius: 10px;
}

.side-menu li:hover {
    color: black;
    background-color: white;
    border-radius: 15px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 1500;
}



/* Initial non-fixed state */
.navbar-ministudy {
    width: 100%;
    z-index: 1000; /* Ensure it stays above other content */
    transition: background 0.3s, box-shadow 0.3s, position 0.3s;
  }
  
  /* Glass effect when scrolled */
  .navbar-ministudy.fixed {
    position: fixed; /* Stick to the top of the page */
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.05); /* Light frosted effect */
    backdrop-filter: blur(20px); /* Blur effect */
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Light border */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Shadow for better visibility */
  }
  
  /* Hide the navbar initially if needed */
  .navbar-ministudy.hidden {
    transform: translateY(-100%);
    transition: transform 0.3s;
  }
  
  /* Navbar shown with animation */
  .navbar-ministudy.visible {
    transform: translateY(0);
  }
  



/* Responsive styles */
@media (max-width: 768px) {
    .navlogo {
        width: 150px;
    }

    .menu-toggle {
        display: block;
    }

    .navbar-ministudy .col-md-8 {
        display: none; /* Hide nav links on tablet and mobile */
    }
}

@media (max-width: 576px) {
    .navlogo {
        width: 120px;
    }

    .menu-toggle {
        display: block;
    }

    .navbar-ministudy .col-md-8 {
        display: none; /* Hide nav links on mobile */
    }
}

/* Side menu open state */
.side-menu.open {
    right: 0;
}

.overlay.active {
    opacity: 1;
    visibility: visible;
}








  .hero{height: 90vh;position: relative;}
  .hero_bg{position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: -1;}
  .hero_bg video{object-fit: cover;width: 100%;height: 100%;overflow: hidden;opacity: 0.3;}
  .hero .lead{font-size: 18px;}




  section{min-height: 100vh;display: flex;align-items: center;background: linear-gradient(
    to bottom,
    rgba(30, 144, 255, 0.05), /* Color with 0.5 opacity */
    rgba(30, 144, 255, 0)    /* Color with 0 opacity */
  );}
  .section_title{margin-bottom: 50px;font-size: xx-large;}
  .sectionTitleImgUnderline{margin-top: 20px;}


  
  .featurebox{height: auto;width: 18%;}
  .featurebox{padding: 30px 0px;overflow: hidden;border-radius: 20px;margin-bottom: 50px;text-align: center;display: flex;flex-direction: column;justify-content: center;align-items: center;background-color: var(--innerbackground);transition: 0.3s ease;border: 1px solid var(--blue);border-image: linear-gradient(to right, #1e90ff, white);border-image-slice: 1;}
  .featurebox:hover{box-shadow: rgba(255, 255, 255, 0.2) 0px 7px 15px 0px;}
  .featurebox img{width: 95px;}
  .featurebox .title{color: white;margin-top: 20px;}
  


 
  .phn_laptop_section .laptop_img{width: 100%;}

  .foursections{background-color: var(--innerbackground);height: auto;width: 23%;border: 1px solid transparent;padding: 10px;border-image: linear-gradient(to right, #1e90ff, white);border-image-slice: 1;overflow: hidden !important;}

  .homesec4{box-shadow: var(--shadow);}
  .homesec4 .title{font-weight: 600;font-size: 18px;padding: 10px;}
  .homesec4 .listsection div{padding: 10px 5px;margin: 15px 0px;border-radius: 10px;font-size: 14px;position: relative;padding-left: 25px;}
  .homesec4 .listsection div::before{content: '⭐';
    position: absolute;
    left: 0;}



    .homecontact .form-title{margin-bottom: 25px;}
    .homecontact .form-container {
      background-color: transparent;
      padding: 25px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      width: 300px;
      border: 1px solid var(--blue);
      box-shadow: var(--shadow);
  }
  .homecontact .form-group {
      position: relative;
      margin-bottom: 25px;
  }
  .homecontact .form-input {
      width: 100%;
      padding: 12px 10px 8px;
      border: 1px solid #ffffff;
      border-radius: 10px;
      font-size: 16px;
      background-color: transparent;
      outline: none;
      color: white;
      box-shadow: var(--shadowlight);
  }
  .homecontact .form-label {
      position: absolute;
      top: 14px; 
      left: 12px;
      font-size: 16px;
      color: #bdbdbd;
      transition: 0.3s ease;
      pointer-events: none;
      background-color:transparent;
      padding: 0 5px; 
  }
  .homecontact .form-input:focus + .form-label,
  .homecontact .form-input:not(:placeholder-shown) + .form-label {
      top: -10px;
      left: 10px;
      font-size: 12px;
      color: white;
      background-color: rgb(16, 16, 16);
      
  }

  footer .container{margin:30px 0px;text-align: center;}
  footer{background: linear-gradient(
    to bottom,
    rgba(30, 144, 255, 0.05), /* Color with 0.5 opacity */
    rgba(30, 144, 255, 0)    /* Color with 0 opacity */
  );}
  

  .foter-sections{}
  .social-media-footer{}
  .social-media-footer img{width: 40px;}

  .responsive-hr {
    display: none; /* Hide by default */
}
@media (max-width: 991.98px) {
  .responsive-hr {
      display: block;
  }
}
  @media (max-width: 576px) {
    .featurebox{height: auto;width: 48%;}
    .Home_feature_sections{margin-top: 30px;}
    .phn_laptop_section{margin-top: 20px;}
    .foursections{width: 100%;margin-top: 20px;}
    .home_contact_us{text-align: center;padding: 30px 0px;}
    .homecontact {margin-top: 30px;}
    .home_whatwedo{text-align: center;}
    .whychooseus,.quicklinks{font-size: 12px;}
    footer h2{font-size: 16px;}
    .social-media-footer img{width: 30px;}
  }

  .custom-cursor {
    position: fixed;
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background-color: red;
    pointer-events: none;
    transition: transform 0.1s ease-out, background-color 0.3s, box-shadow 0.3s;
    will-change: transform, background-color, box-shadow;
    z-index: 1000; 
}

.bubble {
    position: fixed;
    width: 15px;
    height: 15px;
    background-color: rgba(40, 120, 248, 0.7);
    border-radius: 50%;
    pointer-events: none;
    animation: bubble 1s ease-out forwards;
    z-index: 9999; 
}

@keyframes bubble {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(4);
        opacity: 0;
    }
}



/* ==============================about us ============================================== */
.aboutsection{min-height: 60vh;display: flex;align-items: center;}

.headersecabout{position: relative;}
.aboutsection{background: linear-gradient(
    to bottom,
    rgba(30, 144, 255, 0.05), /* Color with 0.5 opacity */
    rgba(30, 144, 255, 0)    /* Color with 0 opacity */
  );}
.headersecabout .bgimg{position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: -1;}
.headersecabout .bgimg img{object-fit: cover;width: 100%;height: 100%;overflow: hidden;opacity: 0.4;}



.contactus_page i{font-size: 20px;padding: 15px;outline: 2px solid var(--blue);border-radius: 20px;}
.contactus_page{text-wrap: wrap;word-wrap: break-word;}
@media (max-width: 576px) {
    .contactus_page i{font-size: 15px;padding: 10px;outline: 2px solid var(--blue);border-radius: 20px;}
    .aboutsection .container{margin-top: 50px;margin-bottom: 50px;padding-top: 20px;padding-bottom: 20px;}
    .weprovide_section,.Home_feature_sections{padding-top: 20px;padding-bottom: 20px;}

}


.contactus_box{outline: 1px solid var(--blue);box-shadow: rgba(255, 255, 255, 0.2) 0px 7px 15px 0px;}
.contactus_box:hover{background-color: #172554;}